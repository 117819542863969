import { inject, InjectionToken } from '@angular/core';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const SUPPLIERS_BFF_URI = new InjectionToken<string>('Suppliers BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/suppliers`;
  },
});

export const SUPPLIERS_URI = new InjectionToken<string>('Suppliers  URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrl}${config.apiUrlPrefix}/suppliers`;
  },
});
